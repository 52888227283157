import CountryList from '@/config/countryList';
export default {
	data() {
		return {
			CountryList,
		};
	},
	computed: {
		getCountryByCode: (that) => (code) => {
			if (!code) return null;
			return that.$store.getters.getCountries.find((country) => country.ISO2 == code || country.ISO3 == code);
		},
		getCountryByName: (that) => (name) => {
			if (!name) return null;
			return that.CountryList.find((country) => country.name.trim().toLowerCase() == name.trim().toLowerCase());
		},
		// getAllCountriesOnData() {
		// 	let allData = [
		// 		...this.$store.getters.getChartData.filter((item) => item.ISO3),
		// 		...this.$store.getters.getArrowData.filter((item) => item.ISO3),
		// 		...this.$store.getters.getHandData.filter((item) => item.ISO3),
		// 		...this.$store.getters.getScores.filter((item) => item.ISO3),
		// 		...this.$store.getters.getVerbalCommitments.filter((item) => item.ISO3),
		// 	];
		// 	let allCountries = allData.map((data) => data.ISO3);
		// 	let uniqueCountries = [...new Set(allCountries)].sort();
		// 	// uniqueCountries = uniqueCountries.filter((country) => !country.toLowerCase().includes('commission') && !country.toLowerCase().includes('council'));
		// 	return uniqueCountries;
		// },
	},
};
